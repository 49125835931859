
import { reactive } from "vue"
import TabsComponent from "../navigation/TabsComponent.vue"

export default {
  name: "ComputerConnectedHardware",
  components: { TabsComponent },
  props: {
    computer: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const tabItemsHardware = reactive([
      { label: "Panels", routeName: "connectedPanels" },
      { label: "Generators", routeName: "connectedGenerators" },
      { label: "Measurers", routeName: "connectedMeasurers" },
    ])
    return {
      tabItemsHardware,
    }
  },
}
